import React from "react"
import { SafeArea } from "../SafeArea"
import {
  Root,
  Slice,
  Container,
  PrivacyContainer,
  Header,
  TextHeader,
  Subheader,
  TextContainer,
} from "./styled"

type PrivacyProps = {}

export const Privacy: React.FC<PrivacyProps> = props => {
  return (
    <Root flexWrap="wrap" flex={1}>
      <Slice />
      <SafeArea>
        <Container
          height="100%"
          flexWrap="wrap"
          justifyContent="left"
          alignItems="left"
        >
          <Header>
            <TextHeader>
              Datenschutzrichtlinie zu SparkThink von Slalom
            </TextHeader>
          </Header>
          <PrivacyContainer>
            <TextContainer>
              <p>
                Diese Datenschutzrichtlinie („
                <b>Datenschutzrichtlinie zu SparkThink</b>“) von Slalom, LLC,
                dba Slalom Consulting und seinen Tochtergesellschaften („
                <b>Slalom</b>“, „<b>wir</b>“ oder „<b>uns</b>“) gilt für das
                Umfragetool SparkThink („<b>SparkThink</b>“).
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                Diese Richtlinie (zusammen mit unserer{" "}
                <a href="https://www.slalom.com/de-de/cookie-richtlinie/?lp=1">
                  Cookies-Richtlinie
                </a>{" "}
                Cookies-Richtlinie und allen anderen darin referenzierten
                Dokumenten) bildet die Grundlage für unsere Verarbeitung aller
                personenbezogenen Daten, die wir von Ihnen über SparkThink
                erheben, wenn Slalom eine Umfrage über SparkThink durchführt,
                oder die Sie Slalom über SparkThink bereitstellen, wenn Sie an
                einer Umfrage von Slalom teilnehmen.{" "}
                <b>
                  Diese Richtlinie gilt <u>nicht</u> für personenbezogene Daten,
                  die wir offline oder über andere Onlineseiten (wie unsere
                  Website <a href="https://www.slalom.com">www.slalom.com</a>)
                  erheben. Informationen zu den Datenschutzpraktiken unserer
                  Kunden, wenn wir eine Umfrage im Auftrag eines Dritten
                  durchführen, erhalten Sie in den Datenschutzrichtlinien und
                  -hinweisen des Unternehmens, von dem Sie den Umfragelink
                  erhalten haben (sofern Sie den Link nicht direkt von Slalom
                  erhalten haben), und/oder des Unternehmens, das als das
                  Unternehmen angegeben ist, das die Umfrage durchführt.
                </b>
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                <em>
                  Einwohner des Europäischen Wirtschaftsraums, des Vereinigten
                  Königreichs oder der Schweiz beachten bitte unsere diese
                  Richtlinie ergänzenden Hinweise zu den Datenschutzgesetzen für
                  den EWR+ <a href="#eea">hier</a>.
                </em>
              </p>
              <p>
                <em>
                  Einwohner Kaliforniens beachten bitte unseren „Bei der
                  Erhebung“-Hinweis zum kalifornischen
                  Verbraucherdatenschutzgesetz <a href="#ccpa">hier</a>.
                </em>
              </p>
              <p>
                <em>
                  Einwohner Mexikos beachten bitte unsere diese Richtlinie
                  ergänzenden Hinweise zu den Datenschutzgesetzen{" "}
                  <a href="#msdp">hier</a>.
                </em>
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                Bitte lesen Sie die folgenden Informationen sorgfältig durch, um
                zu verstehen, wie wir mit Ihren personenbezogenen Daten umgehen
                und wie wir sie behandeln. Mit der Nutzung des SparkThink-Tools
                erklären Sie sich mit den in dieser Richtlinie beschriebenen
                Praktiken einverstanden.
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                Bei der Durchführung der Umfrage erfolgen die Erhebung und
                Kontrolle Ihrer Daten durch das Unternehmen Slalom, LLC (einer
                nach dem Recht des Staates Colorado gegründeten
                US-amerikanischen Gesellschaft mit beschränkter Haftung mit Sitz
                unter der Anschrift 821 2nd Avenue, Suite 1900, Seattle, WA
                98104), das diese Daten innerhalb der Slalom-Gruppe und/oder an
                ein Slalom-Unternehmen, auf das sich die durchgeführte
                SparkThink-Umfrage bezieht („
                <b>entsprechendes Slalom-Unternehmen</b>“), weitergibt. Wenn Sie
                beispielsweise an einer britischen Umfrage teilnehmen, können
                Ihre personenbezogenen Daten zu diesen Zwecken an die Slalom
                Consulting Limited (ein Unternehmen mit Sitz im Vereinigten
                Königreich) weitergegeben werden. In einem solchen Fall können
                Slalom, LLC und das entsprechende Slalom-Unternehmen als
                Auftragsverarbeiter Ihrer Daten nach den jeweils geltenden
                Gesetzen (beispielsweise die Datenschutz-Grundverordnung
                2016/679 und ihre nationalen Umsetzungsvorschriften sowie das
                britische Datenschutzgesetz von 2018 (Data Protection Act 2018)
                (die „<b>Datenschutzgesetze</b>“) gelten). Wir und das
                entsprechende Slalom-Unternehmen können Ihre personenbezogenen
                Daten zudem bisweilen zu den in dieser Datenschutzrichtlinie
                angegebenen Zwecken an unsere anderen Tochtergesellschaften und
                innerhalb der Slalom-Gruppe weitergeben.
              </p>
            </TextContainer>
            <Subheader>Informationen, die wir von Ihnen erheben</Subheader>
            <TextContainer>
              <p>Wir erheben und verarbeiten die folgenden Daten von Ihnen:</p>
              <ul>
                <li>
                  <b>Informationen, die Sie uns geben.</b> Dies sind
                  Informationen über Sie, die Sie uns zur Verfügung stellen,
                  indem Sie an einer Slalom-Umfrage teilnehmen. Die
                  Informationen, die wir von Ihnen erhalten, sind beispielsweise
                  Ihr Name, Ihre Adresse, E-Mail-Adresse und Telefonnummer sowie
                  andere Informationen, die Sie uns zur Verfügung stellen
                  möchten. Die Bereitstellung dieser personenbezogenen Daten ist
                  freiwillig. Wenn Sie uns diese personenbezogenen Daten nicht
                  bereitstellen, können wir Ihre Antworten jedoch nicht in
                  unsere Umfrage aufnehmen und Ihnen nicht die Informationen
                  oder Leistungen bereitstellen, die Sie von uns anfordern. Sie
                  wissen, welche personenbezogenen Daten Sie uns bereitstellen,
                  da Sie sie uns aktiv übermitteln, und welche personenbezogenen
                  Daten Sie uns bereitstellen, richtet sich nach der Umfrage, an
                  der Sie mit dem SparkThink-Tool teilnehmen.
                </li>
                <li>
                  <b>Informationen, die wir über Sie erheben.</b> Neben den von
                  Ihnen aktiv bereitgestellten personenbezogenen Daten kann
                  SparkThink folgende Informationen automatisch erheben,
                  verarbeiten und speichern, damit Slalom die Leistung des
                  SparkThink-Tools überwachen und sicherstellen kann sowie
                  Trends, die Nutzung und Aktivitäten in Verbindung mit den
                  Seiten analysieren kann:
                  <ul>
                    <li>
                      technische Informationen, einschließlich der Domäne und
                      des Hostnamens, von dem aus Sie auf das Internet
                      zugreifen, die Internetprotokoll(IP)-Adresse, mit der Ihr
                      Computer mit dem Internet verbunden wurde, Browsertyp und
                      -version, Zeitzonen- und Landeseinstellungen, Typen und
                      Versionen der Browser-Plug-ins, Betriebssystem und
                      Plattform,
                    </li>
                    <li>
                      Informationen über Ihren Besuch, einschließlich der
                      vollständigen Uniform Resource Locators (URL), von Ihnen
                      angesehene oder gesuchte Dienste, Seitenantwortzeiten,
                      Downloadfehler, Länge der Besuche bestimmter Seiten,
                      Seiteninteraktionsinformationen (wie Scrollen, Klicks und
                      Mouseover) sowie der Verfahren, mit denen die Seite
                      verlassen wurde.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Aus anderen Quellen erhaltene Informationen.</b> Wir
                  arbeiten eng mit Dritten (wie Marketingdiensten und
                  Personalagenturen) zusammen, die uns weitere Informationen
                  über Sie bereitstellen können.
                </li>
              </ul>
            </TextContainer>
            <Subheader>Cookies</Subheader>
            <TextContainer>
              <p>
                SparkThink verwendet Cookies, um Sie von anderen Nutzern von
                SparkThink zu unterscheiden. Dadurch können wir Ihnen bei der
                Verwendung von SparkThink ein gutes Erlebnis bieten und
                SparkThink individuell anpassen und verbessern. Detaillierte
                Informationen zu den von uns verwendeten Cookies und den
                Zwecken, für die wir die Cookies einsetzen, erhalten Sie in
                unserer 
                <a href="https://www.slalom.com/de-de/cookie-richtlinie/?lp=1">
                  Cookie-Richtlinie
                </a>
                . Einige Webbrowser enthalten eine Do-Not-Track(„DNT“)-Funktion
                oder Einstellung mit deren Aktivierung Sie signalisieren, dass
                keine Daten über Ihre Online-Browsing-Aktivitäten überwacht und
                erhoben werden sollen. Sie haben in unserem{" "}
                <u>Datenschutz-Einstellungszentrum</u> bestimmte
                Wahlmöglichkeiten bezüglich Cookies und ähnlicher Technologien,
                um Ihre Entscheidung gegen eine Online-Nachverfolgung zu
                kommunizieren. Wir reagieren jedoch nicht auf DNT-Signale.
              </p>
            </TextContainer>
            <Subheader>Verwendung der Informationen</Subheader>
            <TextContainer>
              <p>
                Wir verwenden die über Sie gespeicherten Informationen wie
                folgt:
              </p>
              <ul>
                <li>
                  <b>Informationen, die Sie uns geben.</b> Wir verwenden diese
                  Informationen:
                  <ul>
                    <li>
                      um die Pflichten aus den zwischen Ihnen und uns
                      geschlossenen Verträgen zu erfüllen und Ihnen die von uns
                      angeforderten Informationen, Produkte und Leistungen
                      zukommen zu lassen;
                    </li>
                    <li>
                      um Ihnen Informationen über andere von uns angebotene
                      Produkte und Dienstleistungen zukommen zu lassen, die
                      denen ähnlich sind, die Sie bereits gekauft oder angefragt
                      haben;
                    </li>
                    <li>
                      um für Sie möglicherweise interessante Informationen über
                      Produkte oder Dienstleistungen Ihnen zukommen zu lassen
                      oder ausgewählten Dritten zu gestatten, Ihnen solche
                      Informationen zukommen zu lassen. Wir kontaktieren Sie
                      ausschließlich auf elektronischem Wege (E-Mail oder SMS)
                      mit Informationen über Produkte und Dienstleistungen, die
                      denen ähnlich sind, die Gegenstand früherer Verkäufe oder
                      Verhandlungen eines Verkaufs an Sie waren, oder wenn Sie
                      dem Erhalt solcher Informationen zugestimmt haben oder wir
                      zum Versand solcher Informationen gesetzlich berechtigt
                      sind.
                    </li>
                    <li>
                      um Sie über Änderungen an unserem Service zu informieren
                      und
                    </li>
                    <li>
                      um sicherzustellen, dass die Inhalte von SparkThink für
                      Sie und Ihren Computer möglichst effektiv präsentiert
                      werden.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Informationen, die wir über Sie erheben.</b> Wir verwenden
                  diese Informationen:
                  <ul>
                    <li>
                      um SparkThink zu verwalten sowie für interne Abläufe,
                      einschließlich Fehlerbehebung, Datenanalyse, Testung,
                      Recherche sowie statistische und umfragebezogene Zwecke;
                    </li>
                    <li>
                      um SparkThink zu verbessern und um sicherzustellen, dass
                      die Inhalte für Sie und für Ihren Computer möglichst
                      effektiv präsentiert werden;
                    </li>
                    <li>
                      im Rahmen unserer Maßnahmen, die Sicherheit von SparkThink
                      zu wahren und
                    </li>
                    <li>
                      um Ihnen und anderen Nutzern von SparkThink Vorschläge und
                      Empfehlungen über Produkte oder Dienstleistungen zu
                      präsentieren, die für Sie oder anderen Nutzer von
                      Interesse sein könnten.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Aus anderen Quellen erhaltene Informationen.</b> Wir
                  kombinieren diese Informationen mit Informationen, die wir von
                  Ihnen erhalten haben und die wir über Sie erhoben haben. Wir
                  verwenden diese Informationen und die kombinierten
                  Informationen für die vorstehend angegebenen Zwecke (in
                  Abhängigkeit von den Arten der erhaltenen Informationen).
                </li>
              </ul>
            </TextContainer>
            <Subheader>Offenlegung Ihrer Informationen</Subheader>
            <TextContainer>
              <p>
                Sie nehmen zur Kenntnis, dass wir zu folgender Weitergabe Ihrer
                personenbezogenen Informationen berechtigt sind:
              </p>
              <ul>
                <li>
                  an alle Unternehmen unseres Konzerns, also an unsere
                  Tochtergesellschaften.
                </li>
                <li>
                  an ausgewählte Dritte, wie die folgenden:
                  <ul>
                    <li>
                      Dienstleister, die als Auftragsverarbeiter von Slalom
                      handeln und vertraglich verpflichtet sind, technische und
                      organisatorische Sicherheitsvorkehrungen zum Schutz der
                      personenbezogenen Daten umzusetzen und die
                      personenbezogenen Daten ausschließlich nach Anweisung zu
                      verarbeiten. Diese dritten Dienstleister unterstützen uns
                      bei der Bewertung unserer
                      Informationssicherheitskontrollen, der Aufrechterhaltung
                      von SparkThink, der Analyse des SparkThink-Datenverkehrs
                      und bei der Bereitstellung der Dienstleistungen und
                      Informationen für uns und die Nutzer von SparkThink;
                    </li>
                    <li>
                      Analyse- und Suchmaschinenanbieter, die uns bei der
                      Verbesserung und Optimierung von SparkThink unterstützen;
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Wir geben Ihre personenbezogenen Informationen auch in folgenden
                Fällen an Dritte weiter:
              </p>
              <ul>
                <li>
                  Für den Fall, dass wir Unternehmen oder Vermögenswerte
                  verkaufen oder kaufen, in welchem Fall wir Ihre
                  personenbezogenen Daten an die potenziellen Verkäufer oder
                  Käufer dieser Unternehmen oder Vermögenswerte weitergeben.
                </li>
                <li>
                  Wenn Slalom, LLC oder ihre Tochtergesellschaften oder im
                  Wesentlichen alle Vermögenswerte Vorstehender von einem
                  Dritten erworben werden, in welchem Fall die gespeicherten
                  personenbezogenen Daten zu einem übertragenen Vermögenswert
                  werden.
                </li>
                <li>
                  Wenn wir gesetzlich oder vertraglich zur Weitergabe Ihrer
                  personenbezogenen Daten verpflichtet sind oder wenn wir Ihre
                  personenbezogenen Daten zum Schutz der Rechte, des Eigentums
                  oder der Sicherheit der Slalom, LLC oder ihrer
                  Tochtergesellschaften, unserer Mitarbeiter, Kunden oder
                  anderer weitergeben.
                </li>
              </ul>
            </TextContainer>
            <Subheader>Grenzüberschreitende Übermittlungen</Subheader>
            <TextContainer>
              <p>
                Ihre personenbezogenen Daten können an Mitarbeiter, Vertreter
                oder andere Dritte weitergegeben werden, die außerhalb des
                Landes tätig sind, in dem Sie ansässig sind, oder die von uns
                oder unseren Tochtergesellschaften in anderen Ländern beauftragt
                wurden, einschließlich der USA und anderer Länder, in denen wir
                tätig sind, sowie an unsere Dienstleister, die sich außerhalb
                des Landes befinden, in dem Sie ansässig sind, einschließlich
                der USA und anderer Länder. Diese Personen können
                personenbezogene Daten auch außerhalb des Landes, in dem Sie
                ansässig sind, speichern, übermitteln und abrufen.
              </p>
              <p>
                Bei der Weitergabe Ihrer personenbezogenen Daten ins Ausland
                treffen wird die Maßnahmen, die unter den jeweiligen Umständen
                angemessen sind, um sicherzustellen, dass die ausländischen
                Empfänger Ihre personenbezogenen Daten in Übereinstimmungen mit
                den geltenden Gesetzen schützen.
              </p>
            </TextContainer>
            <Subheader>Kontakt und Beschwerden</Subheader>
            <TextContainer>
              <p>
                Ihre personenbezogenen Daten können an Mitarbeiter, Vertreter
                oder andere Dritte weitergegeben werden, die außerhalb des
                Landes tätig sind, in dem Sie ansässig sind, oder die von uns
                oder unseren Tochtergesellschaften in anderen Ländern beauftragt
                wurden, einschließlich der USA und anderer Länder, in denen wir
                tätig sind, sowie an unsere Dienstleister, die sich außerhalb
                des Landes befinden, in dem Sie ansässig sind, einschließlich
                der USA und anderer Länder. Diese Personen können
                personenbezogene Daten auch außerhalb des Landes, in dem Sie
                ansässig sind, speichern, übermitteln und abrufen.
              </p>
              <p>
                Bei der Weitergabe Ihrer personenbezogenen Daten ins Ausland
                treffen wird die Maßnahmen, die unter den jeweiligen Umständen
                angemessen sind, um sicherzustellen, dass die ausländischen
                Empfänger Ihre personenbezogenen Daten in Übereinstimmungen mit
                den geltenden Gesetzen schützen.
              </p>
              <p>
                Fragen und Kommentare zu dieser Datenschutzrichtlinie sind
                willkommen und sind per E-Mail an{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a> oder
                postalisch an Slalom, LLC, 821 2nd Avenue, Suite 1900, Seattle,
                WA 98104, USA, zu richten. Etwaige Beschwerden über unsere
                Datenschutzpraktiken können Sie uns unter diesen Kontaktdaten
                ebenfalls zukommen lassen. Sie können des Weiteren auch
                berechtigt sein, Beschwerde bei den zuständigen
                Datenschutzbehörden in Ihrem Land einzureichen.
              </p>
            </TextContainer>
            <Subheader>
              Änderungen an der Datenschutzrichtlinie zu SparkThink
            </Subheader>
            <TextContainer>
              <p>
                Wir können bisweilen Änderungen an diesem Hinweis vornehmen,
                einschließlich wesentlicher Änderungen. Bitte lesen Sie diesen
                Hinweis bei jeder Verwendung von SparkThink, um sich über
                etwaige Änderungen zu informieren.
              </p>
              <p>Wirksamkeitsdatum: 1. August 2022</p>
            </TextContainer>
            <Subheader id="eea">
              Ergänzende Hinweise zu den Datenschutzgesetzen für den EWR+
            </Subheader>
            <TextContainer>
              <p>
                Ergänzend zur Datenschutzrichtlinie zu SparkThink gelten für
                Einwohner des Europäischen Wirtschaftsraums, des Vereinigten
                Königreichs oder der Schweiz (EWR+) folgende Informationen.
              </p>
              <p>
                Verantwortlicher: Der Verantwortliche ist Slalom mit den
                vorstehend angegebenen Kontaktdaten.
              </p>
              <p>
                <u>Empfänger in Drittländern</u>: Die personenbezogenen Daten,
                die wir über Sie erheben oder erhalten, können an Empfänger
                übermittelt oder von Empfängern verarbeitet werden, die sich
                innerhalb oder außerhalb des EWR+ befinden und die aus
                rechtlicher Sicht des EWR+ keinen ausreichenden Datenschutz
                bieten. Diese Dritten sind im Allgemeinen Cloudhosting- und
                Internetdienstleister, Betriebssystem- und Plattformanbieter
                sowie bestimmte{" "}
                <a href="https://www.slalom.com/de-de/plattformen/?lp=1">
                  Technologiepartner
                </a>
                . Bei der Nutzung des SparkThink-Tools oder bei der
                Kontaktaufnahme mit der Slalom, LLC übermitteln Sie
                personenbezogene Daten in die USA, und die Slalom, LLC kann Ihre
                personenbezogenen Daten ins Vereinigte Königreich, nach
                Australien, Japan, Kanada, in die Schweiz, nach Irland, Mexiko
                und Neuseeland übermitteln. Übermittlungen von der Schweiz ins
                Vereinigte Königreich, nach Kanada, Neuseeland und Irland
                erfordern keine zusätzlichen Garantien. Die Grundlage für
                Übermittlungen von der Schweiz nach Australien, Japan, Mexiko
                und in die USA bildet Ihre Einwilligung. Sofern Ihre
                personenbezogenen Daten in Länder übermittelt werden, die aus
                rechtlicher Sicht der EU oder des Vereinigten Königreichs kein
                angemessenes Schutzniveau bieten, bilden die geeigneten
                Garantien (Artikel 46 DSGVO/britische DSGVO) die Grundlage
                dafür, wie die von der Europäischen Kommission angenommenen
                Standarddatenschutzklauseln. Sie können eine Kopie dieser
                Garantien anfordern, indem Sie uns unter den im Abschnitt
                „Kontakt und Beschwerden“ angegebenen Daten kontaktieren.
              </p>
              <p>
                <u>Zeitraum der Datenspeicherung</u>: Ihre personenbezogenen
                Daten werden so lange gespeichert, wie es erforderlich ist, um
                Ihnen die angeforderten Dienstleistungen bereitzustellen, was in
                den meisten Fällen den Zeitraum von 5 Jahren nicht übersteigt.
                Wenn Slalom Ihre personenbezogenen Daten zur Erfüllung
                vertraglicher oder gesetzlicher Pflichten nicht mehr benötigt,
                löschen wir diese aus unseren Systemen und Aufzeichnungen
                und/oder treffen Maßnahmen, um sie ordnungsgemäß zu
                anonymisieren, sodass Sie nicht mehr anhand dieser Daten
                identifiziert werden können, es sei denn, wir müssten Ihre
                Informationen, einschließlich Ihrer personenbezogenen Daten, aus
                steuerlichen, prüfungsbezogenen oder rechtlichen Gründen über
                einen gesetzlich vorgeschriebenen Zeitraum weiter speichern oder
                wir müssen sie zur Beweissicherung aufgrund von
                Verjährungsvorschriften aufbewahren.
              </p>
              <p>Rechte betroffener Personen:</p>
              <p>
                Sie können über die folgenden gesetzlichen Rechte in Bezug auf
                Ihre personenbezogenen Daten verfügen: Diese können nach den
                geltenden Datenschutzgesetzen beschränkt sein.
              </p>
              <p>
                (i) Recht, von uns Auskunft über personenbezogene Daten zu
                verlangen: Sie haben das Recht, sich von uns bestätigen zu
                lassen, ob Ihre personenbezogenen Daten verarbeitet werden, und
                wenn dies der Fall ist, Auskunft über diese personenbezogenen
                Daten zu verlangen, einschließlich der Kategorien der
                verarbeiteten personenbezogenen Informationen, des Zwecks der
                Verarbeitung und der Empfänger oder Kategorien von Empfängern.
                Sie haben das Recht, eine Kopie der personenbezogenen Daten zu
                verlangen, die Gegenstand der Verarbeitung sind. Allerdings ist
                dies kein uneingeschränktes Recht und die Interessen anderer
                Einzelpersonen können Ihr Auskunftsrecht beschränken.
              </p>
              <p>
                (ii) Recht auf Berichtigung: Sie haben das Recht, von uns die
                Berichtigung Sie betreffender unrichtiger oder unvollständiger
                personenbezogener Daten zu verlangen. Unter Berücksichtigung der
                Zwecke der Verarbeitung haben Sie möglicherweise das Recht, die
                Vervollständigung unvollständiger personenbezogener Daten – auch
                mittels einer ergänzenden Erklärung – zu verlangen.
              </p>
              <p>
                (iii) Recht auf Löschung Ihrer personenbezogenen Daten (Recht
                auf Vergessenwerden): Sie haben das Recht, von uns zu verlangen,
                dass Sie betreffende personenbezogene Daten gelöscht werden.
              </p>
              <p>
                (iv) Recht auf Einschränkung der Verarbeitung: In Einzelfällen
                können Sie von uns die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten verlangen.
              </p>
              <p>
                (v) Recht auf Datenübertragbarkeit: Sie haben möglicherweise das
                Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns
                bereitgestellt haben, in einem strukturierten, gängigen und
                maschinenlesbaren Format zu erhalten, und Sie haben
                möglicherweise das Recht, diese personenbezogenen Daten einer
                anderen Stelle ohne Behinderung durch uns zu übermitteln.
              </p>
              <p>
                <b>
                  vi) Widerspruchsrecht: Sie haben unter bestimmten Umständen
                  möglicherweise das Recht, aus Gründen, die sich aus ihrer
                  besonderen Situation ergeben, jederzeit gegen die Verarbeitung
                  Sie betreffender personenbezogener Daten Widerspruch
                  einzulegen; dies gilt auch für ein Profiling, sodass wir Ihre
                  personenbezogenen Daten nicht länger verarbeiten dürfen.
                </b>
              </p>
              <p>
                <b>
                  Werden Ihre personenbezogene Daten verarbeitet, um
                  Direktwerbung zu betreiben, so haben Sie zudem das Recht,
                  jederzeit Widerspruch gegen die Verarbeitung Sie betreffender
                  personenbezogener Daten zum Zwecke derartiger Werbung
                  einzulegen; dies gilt auch für das Profiling, soweit es mit
                  solcher Direktwerbung in Verbindung steht. In diesem Fall
                  werden die personenbezogenen Daten nicht mehr für diese Zwecke
                  von uns verarbeitet.
                </b>
                <br />
                In Abhängigkeit von Ihrem Wohnort haben Sie zudem möglicherweise
                das Recht, eine Beschwerde bei einer Aufsichtsbehörde
                einzureichen. Wenn Sie in Verarbeitungstätigkeiten zu
                personenbezogenen Daten eingewilligt haben, können Sie diese
                Einwilligung jederzeit mit zukünftiger Wirkung widerrufen. Ein
                solcher Widerruf berührt nicht die Rechtmäßigkeit der
                Verarbeitung vor dem Widerruf der Einwilligung. Sie können Ihre
                Rechte ausüben, indem Sie uns unter den im Abschnitt „Kontakt
                und Beschwerden“ angegebenen Daten kontaktieren.
              </p>
              <p>
                <u>Nichtbereitstellung personenbezogener Daten:</u> Wenn wir
                gesetzlich dazu verpflichtet sind, Ihre personenbezogenen Daten
                zu erheben, oder Ihre personenbezogenen Daten gemäß den
                Bedingungen eines mit Ihnen geschlossenen Vertrags erheben
                müssen und Sie diese trotz unserer Aufforderung nicht
                bereitstellen, sind wir möglicherweise nicht in der Lage, den
                Vertrag zu erfüllen, den wir mit Ihnen geschlossen haben oder
                mit Ihnen zu schließen beabsichtigen. Dies kann Anwendung
                finden, wenn Sie die personenbezogenen Daten nicht
                bereitstellen, die wir benötigen, um die von uns in Auftrag
                gegebenen Leistungen zu erbringen. In diesem Fall müssen wir
                möglicherweise die Erbringung der jeweiligen Leistungen für Sie
                einstellen, worüber Sie von uns informiert werden.
              </p>
              <p>
                Zu Ihre Information:
                <ul>
                  <li>
                    Anfragen bezüglich unserer europäischen Unternehmen werden
                    im Allgemeinen von der Slalom Consulting Limited bearbeitet,
                    einer in England und Wales eingetragenen Gesellschaft mit
                    der Unternehmensnummer 08874662 und dem eingetragenen Sitz
                    unter der Anschrift 2 London Bridge, 2nd Floor East, London,
                    SE1 9RA, Vereinigtes Königreich;
                  </li>
                  <li>
                    Anfragen bezüglich unserer kanadischen Unternehmen werden im
                    Allgemeinen von der Slalom Consulting ULC bearbeitet, einer
                    nach den Gesetzen von British Columbia gegründeten
                    kanadischen Gesellschaft mit beschränkter Haftung und dem
                    eingetragenen Sitz unter der Anschrift 745 Thurlow Street,
                    Suite 2400, Vancouver, BC V6E 0C5, Kanada;
                  </li>
                  <li>
                    Anfragen bezüglich unserer US-amerikanischen und
                    mexikanischen Unternehmen werden im Allgemeinen von der
                    Slalom, LLC bearbeitet, einer nach den Gesetzen des Staates
                    Colorado organisierten Gesellschaft mit beschränkter Haftung
                    und dem Sitz unter der Anschrift 821 2nd Avenue, Suite 1900,
                    Seattle, WA 98104, USA;
                  </li>
                  <li>
                    Anfragen bezüglich unserer japanischen Unternehmen werden im
                    Allgemeinen von der Slalom Japan Co., Ltd. bearbeitet, einer
                    japanischen Aktiengesellschaft mit eingetragenem Sitz unter
                    der Anschrift Shiroyama Trust Tower, 21F 4 Chrome 3-1
                    Toranomon, Minato-ku Tokyo 105-6021, Japan;
                  </li>
                  <li>
                    Anfragen bezüglich unserer australischen Unternehmen werden
                    im Allgemeinen von der Slalom Australia Pty Ltd bearbeitet,
                    einer in Australien gegründeten und eingetragenen
                    Gesellschaft mit der Unternehmensnummer 635 861 004 und dem
                    eingetragenen Sitz unter der Anschrift Level 17, 100 Queen
                    Street, Melbourne Victoria 30000, Australien;
                  </li>
                  <li>
                    Anfragen bezüglich unserer neuseeländischen Unternehmen
                    werden im Allgemeinen von der Slalom New Zealand Ltd
                    bearbeitet, einer in Neuseeland eingetragenen Gesellschaft
                    mit der Unternehmensnummer 8244300 und dem eingetragenen
                    Sitz unter der Anschrift Level 22 Floor, PWC Tower, 15
                    Customs Street West, Auckland Central, Auckland 1010,
                    Neuseeland;
                  </li>
                </ul>
                es sei denn, wir teilen Ihnen etwas anderes mit.
              </p>
            </TextContainer>
            <Subheader>
              Ergänzende Hinweise zu den Datenschutzgesetzen für Japan
            </Subheader>
            <TextContainer>
              <p>
                <b>1. Informationen zu den relevanten Slalom-Unternehmen</b>
              </p>
              <p>
                Anschrift und Name der Unternehmensvertreter von
                Slalom-Unternehmen, die regelmäßig personenbezogenen
                Informationen von Einzelpersonen in Japan verarbeiten:
              </p>
              <p>
                Slalom, LLC
                <br />
                821 2nd Avenue, Suite 1900, Seattle, WA 98104 <br />
                Unternehmensvertreter: Christopher Burger, Vice President,
                Information Security & Governance,{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.
              </p>
              <p>
                Slalom Japan Co., Ltd.
                <br />
                7F Atago Green Hills Mori Tower, 2 Chome 5-1 Atago, Minato-ku
                Tokyo 105-6207, Japan
                <br />
                Unternehmensvertreter: Ko Asami, General Manager Japan,{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.
              </p>
              <p>
                Informationen zu anderen Unternehmen erhalten Sie unter{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a> oder
                postalisch unter der Anschrift Slalom Consulting, Privacy
                Office, 821 2nd Avenue, Suite 1900, Seattle, WA 98104.
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                <b>
                  2. Gemeinsame Nutzung personenbezogener Daten innerhalb der
                  Slalom-Gruppe
                </b>
              </p>
              <p>
                Wir geben Ihre in dieser Richtlinie beschriebenen
                personenbezogenen Daten an die Slalom-Unternehmen weiter (siehe
                dazu die Liste der Slalom-Unternehmen weiter unten). Diese
                Slalom-Unternehmen verwenden die personenbezogenen Daten für die
                im Abschnitt „Verwendung der Informationen“ genannten Zwecke.
                Das für die Verwaltung von personenbezogenen Daten
                verantwortlich Unternehmen ist die oben angegebene Slalom K.K.
              </p>
              <p>
                <b>Liste der Slalom-Unternehmen</b>
                <br />
                Slalom, LLC
                <br />
                Slalom Consulting, ULC
                <br />
                Slalom Consulting Ltd.
                <br />
                Slalom Australia Pty Ltd.
                <br />
                Slalom Netherlands B.V.
                <br />
                Slalom GmbH
                <br />
                Slalom Limited
                <br />
                Slalom New Zealand Limited
                <br />
                Slalom Switzerland GmbH
                <br />
                Slalom Technology and Management Solutions Limited
                <br />
                Slalom Consulting Mexico S.R.L. de C.V.
              </p>
            </TextContainer>
            <Subheader id="ccpa">
              „Bei der Erhebung“-Hinweis zum kalifornischen
              Verbraucherdatenschutzgesetz
            </Subheader>
            <TextContainer>
              <p>
                In diesem Hinweis zum kalifornischen
                Verbraucherdatenschutzgesetz (California Consumer Privacy Act („
                <b>CCPA</b>“) zur Online-Erhebung beschäftigen wir uns mit den
                Offenlegungsanforderungen gemäß dem California Civil Code
                §1798.100 und den California Attorney General Regulations bei
                oder vor dem Zeitpunkt der Online-Erhebung in Bezug auf
                Informationen, die Slalom von Einwohnern Kaliforniens bei der
                Teilnahme von Einwohnern Kaliforniens an einer von Slalom
                durchgeführten Umfrage zum SparkThink-Tool erhebt.{" "}
                <b>
                  Dieser CCPA-Hinweis bei der Online-Erhebung gilt <u>nicht</u>{" "}
                  für personenbezogene Daten, die wir offline oder über andere
                  Onlineseiten (wie unsere Website{" "}
                  <a href="https://www.slalom.com">www.slalom.com</a>) erheben.
                  Informationen zu den Datenschutzpraktiken unserer Kunden, wenn
                  wir eine Umfrage im Auftrag eines Dritten durchführen,
                  erhalten Sie in den Datenschutzrichtlinien und -hinweisen des
                  Unternehmens, von dem Sie den Umfragelink erhalten haben
                  (sofern Sie den Link nicht direkt von Slalom erhalten haben),
                  und/oder des Unternehmens, das als das Unternehmen angegeben
                  ist, das die Umfrage durchführt.
                </b>
              </p>
              <p>
                Wir erheben die folgenden Kategorien personenbezogener
                Informationen über Verbraucher und zu den folgenden
                geschäftlichen oder kommerziellen Zwecken:
              </p>
              <p>
                Von Ihnen bei der Teilnahme an einer Umfrage über das
                SparkThink-Umfragetool angegebene Informationen. Die
                Informationen, die wir von Ihnen erhalten, sind beispielsweise
                Ihr Name, Ihre E-Mail-Adresse und Telefonnummer sowie andere
                Informationen, die Sie uns zur Verfügung stellen möchten. Die
                Bereitstellung dieser personenbezogenen Daten ist freiwillig.
                Wenn Sie uns diese personenbezogenen Daten nicht bereitstellen,
                können wir Ihnen jedoch nicht die Informationen oder Leistungen
                bereitstellen, die Sie von uns anfordern. Sie wissen, welche
                personenbezogenen Daten Sie uns bereitstellen, da Sie sie uns
                aktiv übermitteln, und welche personenbezogenen Daten Sie uns
                bereitstellen, richtet sich nach der Umfrage, an der Sie mit dem
                SparkThink-Tool teilnehmen. Wir verwenden die uns von Ihnen
                aktiv bereitgestellten Informationen:
                <ul>
                  <li>
                    um die Pflichten aus den zwischen Ihnen und uns
                    geschlossenen Verträgen zu erfüllen und Ihnen die von uns
                    angeforderten Informationen, Produkte und Leistungen
                    zukommen zu lassen;
                  </li>
                  <li>
                    um Ihnen Informationen über andere von uns angebotene
                    Produkte und Dienstleistungen zukommen zu lassen, die denen
                    ähnlich sind, die Sie bereits gekauft oder angefragt haben;
                  </li>
                  <li>
                    um für Sie möglicherweise interessante Informationen über
                    Produkte oder Dienstleistungen Ihnen zukommen zu lassen oder
                    ausgewählten Dritten zu gestatten, Ihnen solche
                    Informationen zukommen zu lassen. Wir kontaktieren Sie
                    ausschließlich auf elektronischem Wege (E-Mail oder SMS) mit
                    Informationen über Produkte und Dienstleistungen, die denen
                    ähnlich sind, die Gegenstand früherer Verkäufe oder
                    Verhandlungen eines Verkaufs an Sie waren, oder wenn Sie dem
                    Erhalt solcher Informationen zugestimmt haben oder wir zum
                    Versand solcher Informationen gesetzlich berechtigt sind.
                  </li>
                  <li>
                    um Sie über Änderungen an unserem Service zu informieren
                  </li>
                  <li>
                    um sicherzustellen, dass die Inhalte von SparkThink für Sie
                    und Ihren Computer möglichst effektiv präsentiert werden.
                  </li>
                </ul>
              </p>
              <p>
                Verbraucher mit einer Sehbehinderung können möglicherweise einen
                Bildschirmleser oder ein anderes Text-zu-Sprache- oder
                Text-zu-Braille-Tool verwenden, um den Inhalt dieses Hinweises
                zu lesen. Wir verkaufen Ihre personenbezogenen Informationen
                nicht und geben sie auch nicht für kontextübergreifende
                verhaltensbezogene Werbung weiter.
              </p>
              <p>
                Ihre personenbezogenen Informationen werden so lange
                gespeichert, wie es erforderlich ist, um Ihnen die angeforderten
                Dienstleistungen bereitzustellen, was in den meisten Fällen den
                Zeitraum von 5 Jahren nicht übersteigt. Wenn Slalom Ihre
                personenbezogenen Daten zur Erfüllung vertraglicher oder
                gesetzlicher Pflichten nicht mehr benötigt, löschen wir diese
                aus unseren Systemen und Aufzeichnungen und/oder treffen
                Maßnahmen, um sie ordnungsgemäß zu anonymisieren, sodass Sie
                nicht mehr anhand dieser Daten identifiziert werden können, es
                sei denn, wir müssten Ihre Informationen, einschließlich Ihrer
                personenbezogenen Daten, aus steuerlichen, prüfungsbezogenen
                oder rechtlichen Gründen über einen gesetzlich vorgeschriebenen
                Zeitraum weiter speichern oder wir müssen sie zur
                Beweissicherung aufgrund von Verjährungsvorschriften
                aufbewahren.
              </p>
              <p>
                Die CCPA-Datenschutzrichtlinie kann unter{" "}
                <a href="https://www.slalom.com/de-de/datenschutz-bestimmungen/?lp=1">
                  https://www.slalom.com/de-de/datenschutz-bestimmungen/?lp=1
                </a>{" "}
                aufgerufen werden.
              </p>
            </TextContainer>
            <Subheader id="msdp">
              Ergänzende Hinweise zu den Datenschutzgesetzen für Mexiko
            </Subheader>
            <TextContainer>
              <p>
                <b>
                  1. Ihre Rechte in Verbindung mit personenbezogenen
                  Informationen
                </b>
              </p>
              <p>
                <ul>
                  <li>
                    <u>Auskunftsrecht</u>
                    <br />
                    Sie haben möglicherweise das Recht, sich von uns bestätigen
                    zu lassen, ob Ihre personenbezogenen Daten verarbeitet
                    werden, und wenn dies der Fall ist, Auskunft über diese
                    personenbezogenen Daten zu verlangen, einschließlich der
                    Kategorien der verarbeiteten personenbezogenen Informationen
                    und des Zwecks der Verarbeitung.
                  </li>
                  <li>
                    <u>Recht auf Berichtigung</u>
                    <br />
                    Sie haben möglicherweise das Recht, Sie betreffende
                    unrichtige oder unvollständige personenbezogene Daten zu
                    berichtigen.
                  </li>
                  <li>
                    <u>Recht auf Löschung</u>
                    <br />
                    Sie haben möglicherweise das Recht, von uns die Löschung Sie
                    betreffender personenbezogener Daten zu verlangen, und wir
                    werden eine solche Anfrage in Übereinstimmung mit den
                    geltenden Gesetzen prüfen.
                  </li>
                  <li>
                    <u>Recht auf Widerspruch</u>
                    <br />
                    Unter bestimmten Umständen haben Sie möglicherweise das
                    Recht, unserer Verarbeitung Ihrer personenbezogenen Daten zu
                    widersprechen, und wir werden eine solche Anfrage in
                    Übereinstimmung mit den geltenden Gesetzen prüfen.
                  </li>
                  <li>
                    <u>Recht auf Widerruf Ihrer Einwilligung.</u>
                    <br />
                    Sie haben das Recht, Ihre uns erteilte Einwilligung in die
                    Verarbeitung Ihrer personenbezogenen Daten zu widerrufen,
                    und wir werden eine solche Anfrage in Übereinstimmung mit
                    den geltenden Gesetzen prüfen.
                  </li>
                </ul>
              </p>
              <p>
                Wenn Sie eines der oben genannten Rechte ausüben möchten oder
                Fragen oder Beschwerden vorbringen möchten, kontaktieren Sie uns
                bitte per E-Mail an{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.
              </p>
              <p>
                Ihre Anfragen werden in Übereinstimmung mit den geltenden
                Gesetzen geprüft. Auf Verlangen können wir Ihnen über Folgendes
                Auskunft geben: (i) die Informationen, die wir von Ihnen
                benötigen, damit wir Sie sowie die Dokumente, die Sie Ihrer
                Anfrage beifügen müssen, zu identifizieren; (ii) die Fristen,
                innerhalb derer wir Ihre Anfrage beantworten; (iii) wie Sie Ihre
                Anfrage einreichen können, einschließlich etwaiger Vorlagen oder
                Mechanismen (falls zutreffend); (iv) das Medienformat, in dem
                wir Ihnen die Informationen zukommen lassen (z. B. per E-Mail,
                CD usw.)
              </p>
            </TextContainer>
          </PrivacyContainer>
        </Container>
      </SafeArea>
    </Root>
  )
}
